@media (max-width: 576px) {
  .container {
    text-align: center;
  }

  .error {
    display: inline-block;
    margin: 50px auto 50px;
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  .container {
    text-align: center;
  }

  .error {
    display: inline-block;
    margin: 50px auto 50px;
    font-size: 20px;
  }
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
  .container {
    text-align: center;
  }

  .error {
    display: inline-block;
    margin: 200px auto 200px;
    font-size: 80px;
  }
}

@media (min-width: 1400px) {

}